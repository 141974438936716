import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import {
  Heading,
  Box,
  Image,
  Skeleton,
  useColorMode,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/core';

import { connect } from 'react-redux';
import { getVersions } from '../../redux/actions/articleActions';

import MagazineFilter from '../../components/books/magazineFilter';
import Headroom from 'react-headroom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Blog({ getVersions }) {
  let query = useQuery();
  let page = query.get('page');
  console.log(page);
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };
  //   const color = { light: 'white', dark: 'white' };
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  React.useEffect(() => {
    async function getData() {
      setData(null);
      const res = await getVersions();
      console.log(res.data);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [page, getVersions]);
  const breakpointColumnsObj = {
    default: 3,
    1300: 3,
    1100: 2,
    1000: 1,
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'}>
      <Helmet>
        <title>أعداد براءات</title>
      </Helmet>
      {isSmallScreen && (
        <Headroom
          className="book-filter"
          style={{
            top: '70px',
            left: '0px',
            right: '0px',
            zIndex: '1',
            transform: 'translate3D(0px, 0px, 0px)',
            transition: 'all .5s ease-in-out 0s',
          }}
        >
          <MagazineFilter />
        </Headroom>
      )}
      {!isSmallScreen && <MagazineFilter />}
      <Box m="7%" mt="2em" mb="160px">
        {!data && (
          <Box textAlign="center">
            <Spinner size="xl" />
          </Box>
        )}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data &&
            data.versions.map(article => (
              <Link key={article.id} to={`/version?id=${article.id}`}>
                <Box
                  bg={bg[colorMode]}
                  borderRadius=".2em"
                  maxW={{ base: '350px', md: '400px' }}
                  shadow="lg"
                  // p="2"
                  pb="4"
                  m="6"
                  mx="auto"
                  cursor="pointer"
                >
                  <Box>
                    <Skeleton w="100%" isLoaded={loaded}>
                      <Image
                        w="100%"
                        borderTopRadius=".2em"
                        onLoad={imageLoaded}
                        src={`${process.env.REACT_APP_STORAGE}/${article.image}`}
                      />
                    </Skeleton>
                    <Box p="4">
                      <Heading fontFamily="diodrum-med !important" size="lg">
                        {article.name}
                      </Heading>
                      <Heading fontFamily="diodrum-med !important" size="md">
                        {article.version}
                      </Heading>

                      <Box
                        fontSize="lg"
                        className="event-body event-body__magazine"
                        dangerouslySetInnerHTML={{
                          __html: article.description,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
        </Masonry>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getVersions: page => dispatch(getVersions(page)) };
};

export default connect(null, mapDispatchToProps)(Blog);
