import React from 'react';
import { Helmet } from 'react-helmet';

import { useParams, useLocation } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/core';
import Further from '../components/books/Further';
import BooksFilter from '../components/books/BooksFilter';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Books() {
  let query = useQuery();
  let furthercoming = query.get('furthercoming');
  let translate = query.get('translate');
  console.log(furthercoming);

  let { category } = useParams();
  console.log(category);
  return (
    <Box mt={{ base: 0, md: '70px' }}>
      <Helmet>
        <title>قريباً من براءات</title>
      </Helmet>
      <BooksFilter />
      <Box
        pr={['10%', '5%', '5%', '3%']}
        pl={['10%', '5%', '5%', '3%']}
        mt="100px"
        mb="100px"
      >
        <Further furthercoming={furthercoming} translate={translate} />
      </Box>
    </Box>
  );
}
