import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  Box,
  Heading,
  Text,
  Image,
  Divider,
  Flex,
  Skeleton,
  useColorMode,
  useMediaQuery,
} from '@chakra-ui/core';
import Masonry from 'react-masonry-css';
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getVersion } from '../../redux/actions/articleActions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Book({ getVersion }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isSmallerThan970] = useMediaQuery('(max-Width: 970px)');
  const id = useQuery().get('id');

  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const bg = { light: 'white', dark: '#151a23' };

  const breakpointColumnsObj = {
    default: 3,
    1300: 3,
    1100: 2,
    1000: 1,
  };

  useEffect(() => {
    async function getData() {
      const res = await getVersion(id);
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [id]);

  const imageLoaded = () => {
    setLoaded(true);
  };

  return (
    <Box mt="100px" mb="100px">
      <Box mt="100px">
        <Flex
          px={['1em', '2%', '7%', '7%']}
          gap="10px"
          direction={isSmallerThan970 ? 'column' : 'row'}
        >
          {data && (
            <>
              <Helmet>
                <title>{data.title}</title>
              </Helmet>
              <Box flex="1" maxW="350px" ml={isSmallerThan970 ? '0' : '8'}>
                <Skeleton isLoaded={loaded}>
                  <Image
                    onLoad={imageLoaded}
                    shadow="lg"
                    src={`${process.env.REACT_APP_STORAGE}/${data.image}`}
                  />
                </Skeleton>
              </Box>
              <Box flex="2">
                <Heading fontFamily="diodrum-med !important" my="2">
                  {data.name}
                </Heading>
                <Text my="4" fontSize="xl">
                  {data.version}
                </Text>
                <Divider />

                <Box
                  fontSize="2xl"
                  className="content"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              </Box>
            </>
          )}
        </Flex>
        <Box px={['1em', '2%', '7%', '7%']} mb="100px">
          <Heading mt="12" fontFamily="diodrum-med !important">
            المقالات
          </Heading>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {data &&
              data.articles.map(article => {
                return (
                  <Link key={article.id} to={`/singlePost?id=${article.id}`}>
                    <Box
                      bg={bg[colorMode]}
                      w="100%"
                      shadow="lg"
                      // p="2"
                      pb="4"
                      // m="4"
                      mt="8"
                      cursor="pointer"
                    >
                      <Skeleton w="100%" isLoaded={loaded}>
                        <Image
                          w="100%"
                          onLoad={imageLoaded}
                          src={`${process.env.REACT_APP_STORAGE}/${article.image}`}
                        ></Image>
                      </Skeleton>
                      <Text
                        m="2"
                        mt="4"
                        fontSize="lg"
                        fontFamily="diodrum-med !important"
                      >
                        {' '}
                        {article.author}{' '}
                      </Text>
                      <Heading m="4"> {article.title} </Heading>
                      <Box
                        m="4"
                        fontSize="xl"
                        className="content event-body event-body__magazine"
                        dangerouslySetInnerHTML={{ __html: article.body }}
                      ></Box>
                    </Box>
                  </Link>
                );
              })}
          </Masonry>
        </Box>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getVersion: id => dispatch(getVersion(id)) };
};

export default connect(null, mapDispatchToProps)(Book);
