import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Button,
  Text,
  useColorMode,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { getStripe } from '../../redux/actions/stripeActions';
import Stripe from './stripe';
import { Helmet } from 'react-helmet';

import NotVerified from '../../components/NotVerified';

function Videos({ auth, getStripe }) {
  const [data, setData] = React.useState(null);
  const [stripe, setStripe] = React.useState(null);
  const { colorMode } = useColorMode();
  const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');

  const bg = { light: 'white', dark: '#151a23' };

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  React.useEffect(() => {
    async function getData() {
      console.log(auth);
      const billable = await getStripe(auth.data.user.id);
      console.log(billable);
      setStripe(billable);
      if (!billable?.data?.subscription?.active) {
        const getIntent = await axios.get(
          `${process.env.REACT_APP_API}/subscribe`,
          { headers }
        );
        console.log(getIntent);
        setData(getIntent);
      }
    }
    getData();
  }, []);

  console.log(data);

  return (
    <Box borderRadius="lg">
      <Helmet>
        <title>اشترك في براءات</title>
      </Helmet>
      {stripe?.data?.subscription?.active ? (
        <Stripe stripe={stripe}></Stripe>
      ) : (
        <Box
          px={{
            base: isSmallerThan500 ? '1.5em' : '15vw',
            md: '70px',
            lg: '150px',
            xl: '50px',
          }}
          textAlign="center"
          mt={{ base: '3em', md: '8em' }}
          mb="100px"
        >
          <Box>
            {!data && <Spinner size="xl" />}
            {data?.data?.message ? (
              <NotVerified message={data.data.message} />
            ) : (
              <SimpleGrid
                spacing={{ base: '8', md: '8', lg: '12', xl: '8' }}
                columns={[1, 1, 2, 2, 4]}
              >
                {data &&
                  data.data.plans.map(plan => {
                    return (
                      <Box
                        p="1.5em"
                        py="2em"
                        d="flex"
                        flexDir="column"
                        justifyContent="space-between"
                        alignContent="space-between"
                        bg={bg[colorMode]}
                        shadow="lg"
                        w="100%"
                        // h="500px"
                      >
                        <Heading>{plan.product.name}</Heading>
                        <Text fontSize="4xl" my="8">
                          {plan.product.description}
                        </Text>
                        <Box>
                          <Text fontWeight="bold" fontSize="4xl">
                            {plan.amount / 100}$/سنوي
                          </Text>
                          <Link
                            to={`/subscribe?plan_id=${plan.id}&plan_name=${plan.product.name}&plan_amount=${plan.amount}`}
                          >
                            <Button
                              mt="6"
                              w="100%"
                              bg="#232323"
                              color="white"
                              _hover={{ bg: '#4e4e4e' }}
                              _focus={{ bg: '#4e4e4e' }}
                              _active={{ bg: '#232323' }}
                            >
                              سجل
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    );
                  })}
              </SimpleGrid>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return { getStripe: userid => dispatch(getStripe(userid)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
