import axios from 'axios';
import * as t from '../types';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const Login =
  ({ email, password }) =>
  async dispatch => {
    //   dispatch({ type: t.AUTH_LOADING });
    let data;

    data = await axios
      .post(
        `${process.env.REACT_APP_API}/auth/login`,
        { email, password },
        { headers }
      )
      .then(res => {
        console.log(res);
        return res;
        //   if (res.data.message) {
        //     dispatch({
        //       type: t.AUTH_ERROR,
        //       payload: res.data,
        //     });
        //   } else {
        //     dispatch({
        //       type: t.AUTH_LOGIN,
        //       payload: res.data,
        //     });
        //   }
      })
      .catch(err => {
        console.log(err);
        return { error: 'حدث خطا ما راجع البيانات واعد المحاولة' };
        //   dispatch({
        //     type: t.AUTH_ERROR,
        //     payload: { message: 'Something went wrong!!!' },
        //   });
      });

    return data;
  };
export const forgetPassword =
  ({ email, website }) =>
  async dispatch => {
    let data;

    data = await axios
      .post(
        `${process.env.REACT_APP_API}/password/email`,
        { email, website },
        { headers }
      )
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(err => {
        console.log(err);
        return err.response;
      });

    return data;
  };
export const postRegister = register => async dispatch => {
  console.log(register);
  //   dispatch({ type: t.AUTH_LOADING });
  let data;

  data = await axios
    .post(
      `${process.env.REACT_APP_API}/auth/signup`,
      { ...register },
      { headers }
    )
    .then(res => {
      console.log(res);
      return res;
      //   dispatch({
      //     type: t.AUTH_REGISTER,
      //     payload: res.data,
      //   });
    })
    .catch(err => {
      console.log(err);
      return { error: 'حدث خطا ما راجع البيانات واعد المحاولة' };
      //   dispatch({
      //     type: t.AUTH_ERROR,
      //     payload: { message: 'Something went wrong!!!' },
      //   });
    });

  return data;
};
export const saveUser = register => async dispatch => {
  console.log(register);
  dispatch({ type: t.AUTH_SAVE, payload: register });
};
export const logout = () => async dispatch => {
  dispatch({ type: t.AUTH_LOGOUT });
  //   window.location.replace('/');
};
export const ClearError = () => async dispatch => {
  dispatch({ type: t.AUTH_CLEAR_ERROR });
};
