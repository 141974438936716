import React from 'react';
import {
  Box,
  ChakraProvider,
  CSSReset,
  useBreakpointValue,
} from '@chakra-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import MessengerCustomerChat from 'react-messenger-customer-chat';

//?  pages
import Index from './pages/index';
import Verified from './pages/Verified';
import Four from './pages/404';
import Register from './pages/Register';
import Subscribe from './pages/Subscribe';
import Login from './pages/Login';
import ForgetPassword from './pages/forgetPassword';

import Profile from './pages/Profile';
import FeaturedBooks from './pages/FeaturedBooks';
import FurtherComing from './pages/FurtherComing';
import BooksByCategory from './pages/BooksByCategory';
import BooksSeries from './pages/BooksSeries';
import SinglePost from './pages/singlePost';
import About from './pages/content/about';
import Publish from './pages/content/publishConditions';
import Policy from './pages/content/policy';
import Question from './pages/content/questions';
import SellPoints from './pages/content/sellPoints';
import Contact from './pages/content/contact';
import Terms from './pages/content/terms';
import Books from './pages/content/books';
import Book from './pages/content/book';
import Diary from './pages/content/diary';
import Magazine from './pages/content/magazine';
import Versions from './pages/content/versions';
import Version from './pages/content/version';
import Message from './pages/content/message';
import Shop from './pages/content/shop';
import Support from './pages/content/support';
import Podcast from './pages/content/podcast';
import Videos from './pages/content/videos';
import Writers from './pages/content/writers';
import Author from './pages/content/Author';

import Navbar from './components/layout/Navbar';
import TopNavbar from './components/layout/TopNavbar';
import Footer from './components/layout/Footer';
//?util
import Up from './util/Up';
import SocialButtons from './util/SocialButtons';
import ScrollToTop from './util/ScrollToTop';

import './styles/globals.css';
import SinglePodcast from './pages/singlePodcast';
import Headroom from 'react-headroom';

function App({ auth }) {
  console.log(process.env.REACT_APP_API);
  let { path, url } = useRouteMatch();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <CSSReset />
      {isSmallScreen && (
        <Headroom style={{ transition: 'all .5s ease-in-out 0s', zIndex: '2' }}>
          <TopNavbar />
        </Headroom>
      )}
      {!isSmallScreen && <TopNavbar />}
      <SocialButtons />
      <ScrollToTop />
      <Box as="main">
        <Switch>
          <Route title="index" exact path="/">
            <Index />
          </Route>
          <Route title="author" exact path="/author/:id">
            <Author />
          </Route>
          <Route title="single-post" exact path="/singlePodcast/:id">
            <SinglePodcast />
          </Route>
          <Route title="Terms" exact path="/terms">
            <Terms />
          </Route>
          <Route title="policy" exact path="/policy">
            <Policy />
          </Route>
          <Route title="question" exact path="/questions">
            <Question />
          </Route>
          <Route title="sell" exact path="/buyrights">
            <SellPoints />
          </Route>
          <Route title="contact" exact path="/contact">
            <Contact />
          </Route>
          <Route title="book" exact path="/book/:id">
            <Book />
          </Route>
          <Route title="subscribe" exact path="/subscribe">
            {auth && auth.data ? <Subscribe /> : <Login></Login>}
          </Route>
          <Route title="verification" exact path="/verified">
            {auth && auth.data ? <Verified /> : <Login></Login>}
          </Route>
          <Route title="forgetpassword" exact path="/forgetPassword">
            {auth && auth.data ? <Index></Index> : <ForgetPassword />}
          </Route>
          <Route title="register" exact path="/register">
            {auth && auth.data ? <Index></Index> : <Register />}
          </Route>
          <Route title="login" exact path="/login">
            {auth && auth.data ? <Index></Index> : <Login></Login>}
            {/* <Login /> */}
          </Route>
          <Route title="featured" exact path="/featured">
            <FeaturedBooks />
          </Route>
          <Route title="further" exact path="/furthercoming">
            <FurtherComing />
          </Route>
          <Route title="books" exact path="/books_by_category">
            <BooksByCategory />
          </Route>
          <Route title="books_by_series" exact path="/books_by_series">
            <BooksSeries />
          </Route>

          <Route title="about" exact path="/about">
            <About />
          </Route>
          <Route title="about" exact path="/publish">
            <Publish />
          </Route>

          <Route title="books" exact path="/books_by_category/:category">
            <BooksByCategory />
          </Route>
          <Route title="books" exact path="/books">
            <Books />
          </Route>

          <Route title="diary" exact path="/diary">
            <Diary />
          </Route>
          <Route title="magazine" exact path="/magazine">
            <Magazine />
          </Route>
          <Route title="versions" exact path="/versions">
            <Versions />
          </Route>
          <Route title="message" exact path="/message">
            {auth && auth.data ? <Message /> : <Login></Login>}
          </Route>

          <Route title="podcast" exact path="/podcast">
            <Podcast />
          </Route>
          <Route title="shop" exact path="/shop">
            <Shop />
          </Route>
          <Route title="support" exact path="/support">
            {/* {auth && auth.data ? <Support /> : <Login></Login>} */}
            <Support />
          </Route>

          <Route title="profile" exact path="/profile">
            {auth && auth.data ? <Profile /> : <Login></Login>}
          </Route>
          <Route title="videos" exact path="/videos">
            <Videos />
          </Route>
          <Route title="writers" exact path="/writers">
            <Writers />
          </Route>

          <Route title="single-post" exact path="/singlePost">
            <SinglePost />
          </Route>
          <Route title="version" exact path="/version">
            <Version />
          </Route>
          <Route title="404">
            <Four />
          </Route>
        </Switch>
      </Box>
      {/* <Up></Up> */}
      <Footer />
      {isSmallScreen && (
        <Headroom
          className="navbar"
          style={{
            position: 'fixed',
            bottom: '0',
            top: 'unset',
            left: '0',
            right: '0',
            zIndex: '1',
            transform: 'translate3d(0px, 0px, 0px)',
            transition: 'all .5s ease-in-out 0s',
          }}
        >
          <Navbar />
        </Headroom>
      )}
      {!isSmallScreen && <Navbar />}
      <MessengerCustomerChat pageId="511490325560386" appId="406218294192351" />
    </>
  );
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(App);
