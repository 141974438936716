import React from 'react';
import { Helmet } from 'react-helmet';

import { useParams, useLocation, Link } from 'react-router-dom';
import { Box, Heading, useBreakpointValue } from '@chakra-ui/core';
import SingleCatBooks from '../components/books/SingleCatBooks';
import BooksFilter from '../components/books/BooksFilter';
import Headroom from 'react-headroom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Books() {
  let query = useQuery();
  let category = query.get('category');
  let translate = query.get('translate');
  console.log(translate);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'} mb="100px">
      <Helmet>
        <title>{category}</title>
      </Helmet>
      {isSmallScreen && (
        <Headroom
          className="book-filter"
          style={{
            top: '70px',
            left: '0px',
            right: '0px',
            zIndex: '1',
            transform: 'translate3D(0px, 0px, 0px)',
            transition: 'all .5s ease-in-out 0s',
          }}
        >
          <BooksFilter />
        </Headroom>
      )}
      {!isSmallScreen && <BooksFilter />}
      <Box px={['10%', '5%', '5%', '3%']} mt="1em" mb="100px">
        <Box m="8">
          <Heading fontFamily="diodrum-bold !important">{category}</Heading>
        </Box>
        <SingleCatBooks translate={translate} category={category} />
      </Box>
    </Box>
  );
}
