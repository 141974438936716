import React from 'react';
import { Box, Flex, Heading, SimpleGrid, Text, Image } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import {
  FaCcMastercard,
  FaCcVisa,
  FaCcPaypal,
  FaCcApplePay,
  FaFacebook,
  FaSoundcloud,
  FaTwitter,
  FaYoutube,
  FaInstagramSquare,
  FaGoodreads,
  FaWhatsapp,
} from 'react-icons/fa';
import News from './FooterNewsLetter';
import account from '../../images/account.png';
import signup from '../../images/signup.png';

export default function Footer() {
  return (
    <Box pb="60px" px={{ base: '0', md: '10%' }} color="white" bg="black">
      <SimpleGrid mb="100px" p="30px" columns={[1, 1, 3, 3]} spacing="8">
        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md" mb=".5em">
            براءات
          </Heading>
          <Text
            color="gray.200"
            _hover={{ textDecoration: 'underline' }}
            fontFamily="diodrum-med !important"
            fontSize="md"
          >
            <News></News>
          </Text>
          <Link to="/about">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              عن براءات
            </Text>
          </Link>

          <Link to="/contact">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              اتصل بنا
            </Text>
          </Link>

          <Link to="/terms">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              الأحكام والشروط
            </Text>
          </Link>
          <Link to="/policy">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              سياسة الخصوصية
            </Text>
          </Link>
          <Link to="/questions">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              أسئلة متكررة
            </Text>
          </Link>

          <Heading
            mt="12"
            fontFamily="diodrum-bold !important"
            size="md"
            mb=".5em"
          >
            التوزيع
          </Heading>
          <Link to="/buyrights">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              نقاط البيع
            </Text>
          </Link>
          <Link to="/message">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              اشترك في براءات
            </Text>
          </Link>
        </Flex>

        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md" mb=".5em">
            الموضوعات
          </Heading>

          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=طسم"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  طسم
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=يتبعهم الغاوون"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  يتبعهم الغاوون
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=يأفكون"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  يأفكون
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=يهيمون"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  يهيمون
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=من كل واد"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  من كل واد
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=لغوّ"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  لغوّ
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=كل يوم في شأن"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  كل يوم في شأن
                </Text>
              </Box>
            </Box>
          </Link>
          <Link
            // style={{ margin: '50px !important' }}
            //   onClick={handleToggle}
            to="/magazine?page=ختم"
          >
            <Box>
              <Box>
                <Text
                  color="gray.200"
                  _hover={{ textDecoration: 'underline' }}
                  fontFamily="diodrum-med !important"
                  fontSize="md"
                >
                  ختم
                </Text>
              </Box>
            </Box>
          </Link>
          <Box mt="12">
            <Link
              // style={{ margin: '50px !important' }}
              //   onClick={handleToggle}
              to="/versions"
            >
              <Box>
                <Box>
                  <Heading
                    _hover={{ textDecoration: 'underline' }}
                    fontFamily="diodrum-bold !important"
                    size="md"
                  >
                    الاعداد
                  </Heading>
                </Box>
              </Box>
            </Link>
          </Box>
        </Flex>

        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md">
            وسائل التواصل الاجتماعي
          </Heading>

          <Flex mt="4">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/baraat15"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaTwitter></FaTwitter>
              </Text>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/%D9%85%D8%AC%D9%84%D8%A9-%D8%A8%D8%B1%D8%A7%D8%A1%D8%A7%D8%AA-264744790861518/"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaFacebook></FaFacebook>
              </Text>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/baraat_magazine/"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaInstagramSquare></FaInstagramSquare>
              </Text>
            </a>

            {/*<a*/}
            {/*  target="_blank"*/}
            {/*  rel="noreferrer"*/}
            {/*  href="https://www.youtube.com/channel/UCSBUh4FMxnG9Wmjio_tJk1w"*/}
            {/*>*/}
            {/*  <Text*/}
            {/*    color="gray.200"*/}
            {/*    _hover={{ textDecoration: 'underline' }}*/}
            {/*    fontFamily="diodrum-med !important"*/}
            {/*    fontSize="28px"*/}
            {/*    m="2"*/}
            {/*  >*/}
            {/*    <FaYoutube></FaYoutube>*/}
            {/*  </Text>*/}
            {/*</a>*/}

            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/393477104442"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaWhatsapp></FaWhatsapp>
              </Text>
            </a>
          </Flex>

          <Box mt="12">
            <Link
              // style={{ margin: '50px !important' }}
              //   onClick={handleToggle}
              to="/publish"
            >
              <Box>
                <Box>
                  <Heading
                    _hover={{ textDecoration: 'underline' }}
                    fontFamily="diodrum-bold !important"
                    size="md"
                  >
                    انشر في براءات{' '}
                  </Heading>
                  <Flex mt="8" direction="column">
                    <Heading
                      fontFamily="diodrum-bold !important"
                      size="md"
                      mb=".8em"
                    >
                      الحساب
                    </Heading>
                    <Link to="/login">
                      <Image src={account} maxW="150px"></Image>
                    </Link>

                    <Link to="/register">
                      <Image src={signup} maxW="150px" mt=".5em"></Image>
                    </Link>
                  </Flex>
                </Box>
              </Box>
            </Link>
          </Box>
        </Flex>
      </SimpleGrid>
      <Flex justifyContent="center">
        <Box dir="ltr">©️ 2021 Almutawassit Books</Box>
      </Flex>
    </Box>
  );
}
