import React from 'react';
import { Box, Flex, Link, useColorMode } from '@chakra-ui/core';
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaPinterest,
  FaSoundcloud,
  FaYoutube,
  FaInstagram,
  FaWhatsapp,
} from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

// import { Link } from 'react-router-dom';

export default function SocialButtons() {
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = { light: '#fff', dark: '#1a202c' };
  const bgIcon = { light: '#000', dark: '#fff' };
  const color = { light: 'white', dark: 'black' };
  return (
    <Box
      d={{ base: 'none', md: 'block' }}
      style={{ position: 'fixed', zIndex: '99', top: '30%', right: 0 }}
    >
      <Flex direction="column">
        <Link
          href="https://www.facebook.com/%D9%85%D8%AC%D9%84%D8%A9-%D8%A8%D8%B1%D8%A7%D8%A1%D8%A7%D8%AA-264744790861518/"
          //   className="social-href "
          style={{ backgroundColor: bgIcon[colorMode] }}
          isExternal
        >
          <Box
            className="facebook"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaFacebook></FaFacebook>
          </Box>
        </Link>
        <Link
          //   className="social-href"
          href="https://www.twitter.com/baraat15"
          style={{ backgroundColor: bgIcon[colorMode] }}
          isExternal
        >
          <Box
            className="twitter"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaTwitter></FaTwitter>
          </Box>
        </Link>
        {/*<Link*/}
        {/*  //   className="social-href"*/}
        {/*  style={{ backgroundColor: bgIcon[colorMode] }}*/}
        {/*  */}
        {/*>*/}
        {/*  <Box*/}
        {/*    className="linkedin"*/}
        {/*    bg={bgIcon[colorMode]}*/}
        {/*    color={color[colorMode]}*/}
        {/*    p="15px"*/}
        {/*    fontSize="18px"*/}
        {/*  >*/}
        {/*    <FaLinkedin></FaLinkedin>*/}
        {/*  </Box>*/}
        {/*</Link>*/}

        {/*<Link*/}
        {/*  //   className="social-href"*/}
        {/*  style={{ backgroundColor: bgIcon[colorMode] }}*/}
        {/*>*/}
        {/*  <Box*/}
        {/*    className="whatsapp"*/}
        {/*    bg={bgIcon[colorMode]}*/}
        {/*    color={color[colorMode]}*/}
        {/*    p="15px"*/}
        {/*    fontSize="18px"*/}
        {/*  >*/}
        {/*    <IoLogoWhatsapp></IoLogoWhatsapp>*/}
        {/*  </Box>*/}
        {/*</Link>*/}
        <Link
          //   className="social-href"
          href="https://www.instagram.com/baraat_magazine/"
          style={{ backgroundColor: bgIcon[colorMode] }}
        >
          <Box
            className="whatsapp"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaInstagram></FaInstagram>
          </Box>
        </Link>
        <Link
          //   className="social-href"
          href="https://wa.me/393477104442"
          style={{ backgroundColor: bgIcon[colorMode] }}
          isExternal
        >
          <Box
            className="whatsapp"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaWhatsapp></FaWhatsapp>
          </Box>
        </Link>
        {/*<Link*/}
        {/*  //   className="social-href"*/}
        {/*  style={{ backgroundColor: bgIcon[colorMode] }}*/}
        {/*>*/}
        {/*  <Box*/}
        {/*    className="whatsapp"*/}
        {/*    bg={bgIcon[colorMode]}*/}
        {/*    color={color[colorMode]}*/}
        {/*    p="15px"*/}
        {/*    fontSize="18px"*/}
        {/*  >*/}
        {/*    <FaSoundcloud></FaSoundcloud>*/}
        {/*  </Box>*/}
        {/*</Link>*/}
      </Flex>
    </Box>
  );
}
