import React, { useState, useEffect } from 'react';
import { Box, Alert, Button, Text, useToast, Flex } from '@chakra-ui/core';

import { connect } from 'react-redux';
import { emailResend } from '../redux/actions/verifyActions';

function NotVerified({ emailResend }) {
  const toast = useToast();
  //   const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const handleResend = async () => {
    setLoading(true);
    const res = await emailResend();
    if (res?.status === 201) {
      setLoading(false);
      toast({
        title: res.data,
        //    description: "We've created your account for you.",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      setLoading(false);
      toast({
        title: 'حدث خطأ ما أعد المحاولة',
        //    description: "We've created your account for you.",
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    }
    console.log(res);
  };
  return (
    <Flex justifyContent="center" direction="column" alignItems="center">
      <Text mb="4" fontFamily="diodrum-med !important">
        ان لم يصلك الايميل أرجو اعادة الارسال
      </Text>
      <Button
        fontFamily="diodrum-med !important"
        bg="#232323"
        color="white"
        _hover={{ bg: '#4e4e4e' }}
        _focus={{ bg: '#4e4e4e' }}
        _active={{ bg: '#232323' }}
        isDisabled={loading}
        isLoading={loading}
        onClick={handleResend}
      >
        اعادة الارسال
      </Button>
    </Flex>
  );
}

const mapDispatchToProps = dispatch => {
  return { emailResend: () => dispatch(emailResend()) };
};

export default connect(null, mapDispatchToProps)(NotVerified);
