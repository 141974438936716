import axios from 'axios';

export const getStripe = userid => async (dispatch, getState) => {
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${auth.data.access_token}`;
  let data;
  data = await axios
    .get(`${process.env.REACT_APP_API}/billable/${userid}`, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
export const getPlans = userid => async (dispatch, getState) => {
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${auth.data.access_token}`;
  let data;
  data = await axios
    .get(`${process.env.REACT_APP_API}/subscribe`, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
export const cancelSub = payload => async (dispatch, getState) => {
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.data.access_token}`,
  };
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${auth.data.access_token}`;

  const { subid, now } = payload;
  console.log(subid, now);
  console.log(headers.Authorization);
  let data;
  data = await axios
    .post(`${process.env.REACT_APP_API}/subscribe/${subid}/cancel?now=${now}`, {
      headers,
    })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
export const resumeSub = subid => async (dispatch, getState) => {
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${auth.data.access_token}`;

  let data;
  data = await axios
    .post(`${process.env.REACT_APP_API}/subscribe/${subid}/resume`, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
export const changeSub = payload => async (dispatch, getState) => {
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${auth.data.access_token}`;
  const { subid, plan } = payload;
  let data;
  data = await axios
    .put(`${process.env.REACT_APP_API}/subscribe/${subid}?plan=${plan}`, {
      headers,
    })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
