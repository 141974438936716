import React from 'react';
import {
  Box,
  Stack,
  Input,
  InputLeftElement,
  Button,
  Flex,
  Heading,
  InputGroup,
  useToast,
  Divider,
  Text,
  useColorMode,
} from '@chakra-ui/core';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Login, saveUser } from '../redux/actions/authAction';

function Register({ Login, saveUser }) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: '#151a23' };

  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [register, setRegister] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = e => {
    setRegister(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    const response = await Login(register);
    console.log(response);
    if (response && response.error) {
      toast({
        title: 'حدث خطا',
        position: 'top-right',
        description: response.error,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    } else if (response && response.status === 200) {
      //? dispatch action to save the user and the token
      saveUser(response);
      setLoading(false);
      window.location.reload();
      console.log('you can dispatch');
    }
  };

  console.log(register);

  return (
    <Box
      fontFamily="diodrum-med !important"
      mt={{ base: '3em', md: '10em' }}
      mb="6em"
      textAlign="center"
    >
      <Helmet>
        <title>تسجيل الدخول</title>
      </Helmet>
      <Box>
        <Flex justifyContent="center">
          <Stack p="8" bg={bg[colorMode]} w="400px">
            <Heading mb="4">تسجيل الدخول</Heading>

            <InputGroup>
              <Input
                onChange={handleChange}
                className="auth-input"
                border="1px solid black"
                borderColor="black"
                type="email"
                name="email"
                placeholder="البريد الالكتروني"
              ></Input>
            </InputGroup>
            <InputGroup>
              <Input
                onChange={handleChange}
                className="auth-input"
                border="1px solid black"
                borderColor="black"
                name="password"
                type={show ? 'text' : 'password'}
                placeholder="كلمة السر"
              ></Input>
              <InputLeftElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? 'حجب' : 'كشف'}
                </Button>
              </InputLeftElement>
            </InputGroup>

            <Button
              isLoading={loading}
              isDisabled={
                register.username === '' ||
                register.email === '' ||
                register.password === '' ||
                register.password_confirmation === ''
              }
              onClick={handleSubmit}
              bg="black"
              color="white"
            >
              دخول
            </Button>
            <Divider></Divider>
            <Flex justifyContent="space-between" mt="4">
              <Link to="/register">
                <Text fontWeight="bold">تسجيل جديد</Text>
              </Link>
              <Link to="/forgetPassword">
                <Text fontWeight="bold">نسيت كلمة السر</Text>
              </Link>
            </Flex>
            {/* <Box color="black">{JSON.stringify(register)}</Box> */}
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    Login: register => dispatch(Login(register)),
    saveUser: register => dispatch(saveUser(register)),
  };
};

export default connect(null, mapDispatchToProps)(Register);
