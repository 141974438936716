import React, { useState, useEffect } from 'react';

import {
  Box,
  Flex,
  Text,
  useColorMode,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  DrawerHeader,
  SimpleGrid,
  Spinner,
  useMediaQuery,
  Center,
} from '@chakra-ui/core';
// import { ChevronDownIcon } from '@chakra-ui/icons';
import { NavLink, Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';

import {
  FaGoogle,
  FaFacebookF,
  FaHamburger,
  FaShoppingCart,
  FaDollarSign,
  FaSearch,
  FaNewspaper,
  FaMoon,
  FaSun,
  FaTimes,
} from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { connect } from 'react-redux';
import { getSeries, getCat } from '../../redux/actions/seriesActions';

function Navbar({ getSeries, getCat }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isSmallerThan420] = useMediaQuery('(max-width:420px)');
  const [isLargerThan790] = useMediaQuery('(min-width: 790px)');
  const [isLargerThan1280] = useMediaQuery('(max-width: 420px)');

  const [data, setData] = useState(null);
  const [cat, setCat] = useState(null);
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const filter = { light: '#000000', dark: '#1a202c' };
  const hoverBg = { light: '#000', dark: 'white' };
  const hoverClr = { light: 'white', dark: '#000' };

  useEffect(() => {
    async function getData() {
      const categories = await getCat();
      if (categories) {
        console.log(categories);
        setCat(categories.data);
      }
      const res = await getSeries();
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, []);

  const handleToggle = () => setShow(!show);

  const onOpened = () => {
    // document.body.style.overflow = 'hidden';
  };

  const onClosed = () => {
    // document.body.style.overflow = '';
  };

  return (
    <Flex
      borderBottom="1px solid #ddd"
      as="nav"
      align="center"
      bg={bg[colorMode]}
      overflowX={{ base: 'auto', sm: 'auto' }}
      wrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
      direction={['row', 'row', 'row', 'row']}
    >
      {!isLargerThan790 && (
        <IconButton
          mr="1em"
          onClick={onOpen}
          icon={<GiHamburgerMenu size="25px" />}
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        />
      )}
      {isLargerThan790 && (
        <Flex>
          <NavLink
            activeStyle={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
              height: '100%',
              marginLeft: 5,
            }}
            to="/featured?featured=1"
          >
            <Flex
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                آخر الإصدارات
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
              height: '100%',
              marginLeft: 5,
            }}
            to="/books"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                جميعها
              </Text>
            </Flex>
          </NavLink>
          <Menu onOpen={onOpened} onClose={onClosed}>
            <MenuButton
              display="block"
              // px={4}
              // py={2}
              whiteSpace="nowrap"
              as={Text}
              //   mt={{ base: 4, md: 0 }}
              p="4"
              fontSize={['lg', '2xl']}
              fontWeight="bold"
              transition="all 0.2s"
              // _hover={{ bg: 'gray.100' }}
              // _focus={{ outline: 0, boxShadow: 'outline' }}
              fontFamily="diodrum-med !important"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              cursor="pointer"
            >
              حسب الصنف
              <FaChevronDown
                style={{
                  display: 'inline',
                  fontSize: 15,
                  marginRight: 3,
                }}
              />
            </MenuButton>
            <MenuList color="white" bg={filter[colorMode]}>
              {!data && (
                <Box w="100" mt="50" mb="50" textAlign="center">
                  <Spinner size="xl" />
                </Box>
              )}
              {cat &&
                cat.map(category => {
                  if (isSmallerThan420) {
                    return (
                      <MenuItem
                        _focus={{ bg: 'white', color: 'black' }}
                        _hover={{ bg: 'white', color: 'black' }}
                        fontSize="xl"
                      >
                        <Link
                          key={category.id}
                          style={{ margin: '50px !important' }}
                          onClick={handleToggle}
                          to={`/books_by_category?category=${category.key}`}
                        >
                          <Heading
                            fontFamily="diodrum-med !important"
                            fontSize={['lg', 'lg', 'xl', 'xl']}
                            px=".5em"
                          >
                            {category.name}
                          </Heading>
                        </Link>
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem
                        _focus={{ bg: 'white', color: 'black' }}
                        _hover={{ bg: 'white', color: 'black' }}
                        fontSize="xl"
                      >
                        <Link
                          key={category.id}
                          style={{ margin: '50px !important' }}
                          onClick={handleToggle}
                          to={`/books_by_category?category=${category.key}`}
                        >
                          <Heading
                            fontFamily="diodrum-med !important"
                            fontSize={['lg', 'lg', 'xl', 'xl']}
                            px=".5em"
                          >
                            {category.name}
                          </Heading>
                        </Link>
                      </MenuItem>
                    );
                  }
                })}
            </MenuList>
          </Menu>
          <NavLink
            activeStyle={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
              height: '100%',
              marginLeft: 5,
            }}
            to="furthercoming?furthercoming=1"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                يأتي قريباً
              </Text>
            </Flex>
          </NavLink>
        </Flex>
      )}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay />
        <DrawerContent
          bg={colorMode === 'dark' ? bg[colorMode] : '#000'}
          color="white"
        >
          <DrawerHeader p="0">
            <IconButton
              p={8}
              onClick={onClose}
              icon={<FaTimes size="25px" />}
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            />
          </DrawerHeader>
          <DrawerBody p="0">
            <Flex direction="column">
              <NavLink
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black',
                  height: '100%',
                  marginLeft: 5,
                }}
                to="/featured?featured=1"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    آخر الإصدارات
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black',
                  height: '100%',
                  marginLeft: 5,
                }}
                to="/books"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    جميعها
                  </Text>
                </Flex>
              </NavLink>
              <Accordion allowToggle>
                <AccordionItem border="none">
                  <AccordionButton
                    pt=".5em"
                    pb="1em"
                    px="2em"
                    _hover={{ bg: 'white', color: '#000' }}
                    _focus={{ bg: 'white', color: '#000' }}
                    _active={{ bg: 'white', color: '#000' }}
                  >
                    <Text
                      whiteSpace="nowrap"
                      fontWeight="bold"
                      fontSize={['lg', '2xl']}
                      fontFamily="diodrum-med !important"
                    >
                      حسب الصنف
                    </Text>
                    <AccordionIcon fontSize="25px" />
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    pt="0"
                    px="0"
                    borderRight="1px solid"
                    borderColor="gray.400"
                  >
                    {cat &&
                      cat.map(category => {
                        return (
                          <Link
                            key={category.id}
                            style={{ margin: '50px !important' }}
                            to={`/books_by_category?category=${category.key}`}
                          >
                            <Box
                              pt=".4em"
                              pb=".8em"
                              px="1em"
                              _hover={{ bg: 'white', color: '#000' }}
                              _focus={{ bg: 'white', color: '#000' }}
                              _active={{ bg: 'white', color: '#000' }}
                            >
                              <Heading
                                dir="rtl"
                                fontFamily="diodrum-med !important"
                                fontSize={['lg', 'lg', '2xl', 'xl']}
                              >
                                {category.name}
                              </Heading>
                            </Box>
                          </Link>
                        );
                      })}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <NavLink
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black',
                  height: '100%',
                  marginLeft: 5,
                }}
                to="furthercoming?furthercoming=1"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    يأتي قريباً
                  </Text>
                </Flex>
              </NavLink>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>{' '}
    </Flex>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getSeries: () => dispatch(getSeries()),
    getCat: () => dispatch(getCat()),
  };
};

export default connect(null, mapDispatchToProps)(Navbar);
