import React from 'react';
import { Box, SimpleGrid, Spinner } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { getVideos } from '../../redux/actions/poscastsActions';
import { Helmet } from 'react-helmet';

import VideoModal from '../../components/VideoModal';

function Podcasts({ getVideos }) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function getData() {
      const res = await getVideos();
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, []);

  const artic =
    data && Object.keys(data.articles).map(key => data.articles[key]);
  const books = data && Object.keys(data.books).map(key => data.books[key]);
  return (
    <Box mt={{ base: '1em', md: '8em' }} mb="8em" pl="10%" pr="10%">
      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      {data && (
        <Box>
          <Helmet>
            <title>فيديو براءات</title>
          </Helmet>

          <SimpleGrid mt="8" mb="4" columns={[1, 1, 3, 3]} spacing="8">
            {data.podcasts.length !== 0 &&
              data.podcasts.map(podcast => (
                <VideoModal podcast={podcast}></VideoModal>
              ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getVideos: () => dispatch(getVideos()) };
};

export default connect(null, mapDispatchToProps)(Podcasts);
