import React, { useState } from 'react';
import axios from 'axios';
// MUI Components
import {
  Box,
  Input,
  Button,
  useColorMode,
  Text,
  useToast,
  Alert,
  Divider,
  Flex,
} from '@chakra-ui/core';
// stripe
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Support({ auth }) {
  //   const [data, setData] = React.useState(null);
  const [amount, setAmount] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Authorization: `Bearer ` + auth.data.access_token,
  };

  //   React.useEffect(() => {
  //     async function getData() {
  //       const getIntent = await axios.get(
  //         `${process.env.REACT_APP_API}/donation`,
  //         { headers }
  //       );
  //       setData(getIntent);
  //     }
  //     getData();
  //   }, []);
  //   console.log(data);

  //subscribe
  const handleSubmitSub = async event => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      //   billing_details: {
      //     email: auth.email,
      //   },
    });

    console.log(result);

    if (result.error) {
      console.log(result.error.message);
    } else {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/donation`,
        {
          payment_method: result.paymentMethod.id,
          amount: amount * 100,
        },
        { headers }
      );
      if (!res.status === 200) {
        toast({
          //  title: 'Account created.',
          description: 'حدث خطا ما اعد المحاولة ',
          position: 'top-right',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          //  title: 'Account created.',
          description: 'تم التبرع بنجاح',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.replace('/');
        }, 1000);
      }
    }
  };

  return (
    <Box
      fontFamily="diodrum-med !important"
      bg={bg[colorMode]}
      shadow="lg"
      p="8"
      mr={['5%', '5%', '30%', '30%']}
      ml={['5%', '5%', '30%', '30%']}
      mt={{ base: '2.5em', md: '6em' }}
      mb="100px"
    >
      <Helmet>
        <title>ادعم براءات</title>
      </Helmet>
      <Alert mb="8" bg="gray.300" color="black">
        <Box>
          <Box mb="4" mr="4">
            مجلة براءات هي مجلة قائمة على جهود فردية، ولا تملك مصدر تمويل أو دخل
            ثابت باستثناء طباعة منشورات المتوسط للمجلة.
          </Box>
          <Box mb="4" mr="4">
            إنَّ دعمكم لمجلة براءات، يُساهم في استمرار مشروع مجلة خاصَّة بالشعر
            ذات هويَّة عربية عابرة للبلدان.
          </Box>
          <Box mb="4" mr="4">
            يمكنكم دعم مجلة براءات من خلال:
          </Box>

          <Box mr="4">- التبرع المباشر بمبلغ مالي.</Box>
          <Box mr="4">
            - الاشتراك في المجلة. <Link to="/message">من هنا</Link>
          </Box>
          <Box mr="4">
            - إذا كنتم تُمثلون مؤسسة أو شركة ما، يمكنكم الإعلان عن نشاطاتكم.{' '}
            <Link to="/contact">من هنا</Link>
          </Box>
          <Box mt="4" mr="4">
            نشكركم مسبقاً على دعمكم.
          </Box>
        </Box>
      </Alert>
      <Text>المبلغ</Text>

      <Input
        mt="4"
        mb="4"
        type="number"
        onChange={e => setAmount(e.target.value)}
        placeholder="المبلغ باليورو"
      ></Input>
      <Text mb="4">رقم البطاقة</Text>

      <CardElement></CardElement>
      <Button
        isDisabled={!amount}
        bg="black"
        color="white"
        w="100%"
        mt="8"
        onClick={handleSubmitSub}
        fontSize="xl"
      >
        تبرع
      </Button>
      <Divider></Divider>
      <Box mt="4">
        <Text>أو يمكنك التبرع باستخدام بايبال</Text>
        <Flex mt="8" justifyContent="center">
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="2Z3N56E6Z6ZD6"
            />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/IT/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/en_IT/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </Flex>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Support);
