import React from 'react';
import { Box, Text, Heading, Image, Divider } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import aboutpic from '../images/about-min.jpg';
export default function blog() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>عن براءات</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        شروط النشر في المجلة
      </Heading>

      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          - أن تكون المواد المُرسلة، تأليفاً أو ترجمةً خاصَّة بمجلَّة براءات،
          ولا تكون قد نُشِرت أو ستُنشَر في أماكن أخرى.
        </Text>
        <Text fontSize="2xl" m="4">
          - يتم الرَّد في حال موافقة هيئة التحرير على النَّشر خلال أسبوع على
          الأكثر من زمن إرسال المادة، وعدم الرَّد بعد هذه المُدَّة يُعتبر رفضاً
          لها.
        </Text>
        <Text fontSize="2xl" m="4">
          - تخضع المواد المُوَافق عليها إلى المراجعة والتحرير إن اقتضى الأمر
          بإشراف هيئة التحرير صاحبة القرار.
        </Text>
        <Text fontSize="2xl" m="4">
          - ليس شرطاً أن تُنشر كلُّ المواد الموافق عليها في النسخة الورقية
          لمجلَّة براءات الفصليَّة، ويبقى الاختيار لهيئة التحرير للمواد التي
          تُنشر ورقياً، وتلك التي تُنشر في الموقع الإلكتروني وحسب.
        </Text>
        <Text fontSize="2xl" m="4">
          ترسل المواد إلى إيميل هيئة التحرير : baraat@baraat.it
        </Text>
      </Box>
    </Box>
  );
}
