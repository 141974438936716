import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  useColorMode,
  Text,
  Flex,
  useToast,
  Spinner,
} from '@chakra-ui/core';

import { connect } from 'react-redux';

import {
  getProfile,
  postProfile,
  updatePassword,
} from '../redux/actions/profileActions';
import NotVerified from '../components/NotVerified';

function Profile({ getProfile, postProfile, updatePassword }) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: '#151a23' };
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ploading, setPloading] = useState(false);
  const [profile, setProfile] = useState();
  const [eprofile, setEprofile] = useState();
  const [password, setPassword] = useState({
    password: '',
    new_password: '',
    new_password_confirmation: '',
  });

  const handleEdit = e => {
    e.persist();
    setEprofile(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(eprofile);
  };
  const handlePassword = e => {
    e.persist();
    setPassword(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(password);
  };
  const submitEdit = async e => {
    setLoading(true);
    const res = await postProfile(eprofile);
    if (res?.status === 200) {
      setProfile(res.data);
      toast({
        title: 'تم تعديل الحساب بنجاح ',
        //    description: "We've created your account for you.",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      toast({
        title: 'حدث خطأ ما أعد المحاولة',
        //    description: "We've created your account for you.",
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    }
    setLoading(false);
    setEdit(false);
    console.log(res);
  };
  const submitPassword = async e => {
    setPloading(true);
    const res = await updatePassword(password);
    setPloading(false);
    if (res?.status === 200) {
      setPassword({
        password: '',
        new_password: '',
        new_password_confirmation: '',
      });
      toast({
        title: 'تم تغيير كلمة السر بنجاح',
        //    description: "We've created your account for you.",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      toast({
        title: 'حدث خطأ ما أعد المحاولة',
        //    description: "We've created your account for you.",
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    }
    console.log(res);
  };

  useEffect(() => {
    async function getprofile() {
      const res = await getProfile();
      setProfile(res.data);
    }
    getprofile();
  }, [getProfile, postProfile, updatePassword]);

  return (
    <Box mt="100px" mb="100px">
      {profile?.message ? (
        <NotVerified message={profile.message} />
      ) : (
        <Box>
          <Box
            fontFamily="diodrum-med !important"
            bg={bg[colorMode]}
            p="2em"
            mx={{ base: '1em', md: '10%' }}
            mt="100px"
            mb="30px"
          >
            {edit ? (
              <>
                <Heading size="md">تعديل الحساب</Heading>
                <Text mt="8">رقم الهاتف</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="phone"
                  defaultValue={profile?.phone}
                  onChange={handleEdit}
                  placeholder="رقم الهاتف"
                ></Input>
                <Text mt="2">البلاد</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="country"
                  defaultValue={profile?.country}
                  onChange={handleEdit}
                  placeholder="البلاد"
                ></Input>
                <Text mt="2">الولاية</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="city"
                  defaultValue={profile?.city}
                  onChange={handleEdit}
                  placeholder="الولاية"
                ></Input>
                <Text mt="2">الدائرة</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="province"
                  defaultValue={profile?.province}
                  onChange={handleEdit}
                  placeholder="الدائرة"
                ></Input>
                <Text mt="2">الرمز البريدي</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="zip_code"
                  defaultValue={profile?.zip_code}
                  onChange={handleEdit}
                  placeholder="الرمز البريدي"
                ></Input>
                <Text mt="2">العنوان</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="address"
                  defaultValue={profile?.address}
                  onChange={handleEdit}
                  placeholder="العنوان"
                ></Input>
                <Text mt="2">العنوان الثانوي</Text>

                <Input
                  mt="4"
                  mb="4"
                  name="address_2"
                  defaultValue={profile?.address_2}
                  onChange={handleEdit}
                  placeholder="العنوان الثانوي"
                ></Input>
                <Box d="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setEdit(false)}
                    colorScheme="red"
                    fontSize="xl"
                    ml="4"
                  >
                    الغاء
                  </Button>
                  <Button
                    isLoading={loading}
                    isDisabled={!eprofile || loading}
                    onClick={submitEdit}
                    colorScheme="teal"
                    fontSize="xl"
                  >
                    حفظ
                  </Button>
                </Box>
              </>
            ) : (
              <>
                {profile ? (
                  <>
                    <Heading size="md">الحساب</Heading>
                    <Box mt="8" mb="8">
                      {profile.name && (
                        <Flex>
                          <Text fontWeight="bold">اسم المستخدم :</Text>
                          <Text mr="8">{profile.name}</Text>
                        </Flex>
                      )}
                      {profile.email && (
                        <Flex>
                          <Text fontWeight="bold">الايميل :</Text>
                          <Text mr="8">{profile.email}</Text>
                        </Flex>
                      )}
                      {profile.phone && (
                        <Flex>
                          <Text fontWeight="bold">رقم الهاتف :</Text>
                          <Text mr="8">{profile.phone}</Text>
                        </Flex>
                      )}
                      {profile.country && (
                        <Flex>
                          <Text fontWeight="bold">البلاد :</Text>
                          <Text mr="8">{profile.country}</Text>
                        </Flex>
                      )}
                      {profile.city && (
                        <Flex>
                          <Text fontWeight="bold">الولاية :</Text>
                          <Text mr="8">{profile.city}</Text>
                        </Flex>
                      )}
                      {profile.province && (
                        <Flex>
                          <Text fontWeight="bold">الدائرة :</Text>
                          <Text mr="8">{profile.province}</Text>
                        </Flex>
                      )}
                      {profile.zip_code && (
                        <Flex>
                          <Text fontWeight="bold">الرمز البريدي :</Text>
                          <Text mr="8">{profile.zip_code}</Text>
                        </Flex>
                      )}
                      {profile.address && (
                        <Flex>
                          <Text fontWeight="bold">العنوان :</Text>
                          <Text mr="8">{profile.address}</Text>
                        </Flex>
                      )}
                      {profile.address_2 && (
                        <Flex>
                          <Text fontWeight="bold">العنوان الثانوي :</Text>
                          <Text mr="8">{profile.address_2}</Text>
                        </Flex>
                      )}
                    </Box>
                    <Box d="flex" justifyContent="flex-end">
                      <Button
                        onClick={() => setEdit(!edit)}
                        colorScheme="blue"
                        fontSize="xl"
                      >
                        تعديل
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Box textAlign="center">
                    <Spinner size="xl" />
                  </Box>
                )}
              </>
            )}
          </Box>
          {profile?.message ? null : (
            <Box
              fontFamily="diodrum-med !important"
              bg={bg[colorMode]}
              p="2em"
              mx={{ base: '1em', md: '10%' }}
              mt="30px"
              mb="100px"
            >
              <Heading size="md">تغيير كلمة السر</Heading>
              <Text mt="8">كلمة السر</Text>

              <Input
                mt="4"
                mb="4"
                name="password"
                value={password?.password}
                onChange={handlePassword}
                placeholder="كلمة السر"
              ></Input>
              <Text>كلمة السر الجديدة</Text>

              <Input
                mt="4"
                mb="4"
                value={password?.new_password}
                name="new_password"
                onChange={handlePassword}
                placeholder="كلمة السر الجديدة"
              ></Input>
              <Text>تأكيد كلمة السر الجديدة</Text>

              <Input
                mt="4"
                mb="4"
                value={password?.new_password_confirmation}
                name="new_password_confirmation"
                onChange={handlePassword}
                placeholder="تأكيد كلمة السر الجديدة"
              ></Input>
              <Box d="flex" justifyContent="flex-end">
                <Button
                  isDisabled={ploading || !password}
                  isLoading={ploading}
                  colorScheme="teal"
                  fontSize="xl"
                  onClick={submitPassword}
                >
                  تغيير
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getProfile: () => dispatch(getProfile()),
    postProfile: payload => dispatch(postProfile(payload)),
    updatePassword: payload => dispatch(updatePassword(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Profile);
