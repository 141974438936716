import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import {
  Heading,
  Box,
  Image,
  Skeleton,
  useColorMode,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/core';

import { connect } from 'react-redux';
import { getArticles } from '../../redux/actions/articleActions';

import MagazineFilter from '../../components/books/magazineFilter';

import Headroom from 'react-headroom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Blog({ getArticles }) {
  let query = useQuery();
  let page = query.get('page');
  console.log(page);
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };
  //   const color = { light: 'white', dark: 'white' };
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  React.useEffect(() => {
    async function getData() {
      setData(null);
      const res = await getArticles(page || 'all');
      console.log(res.data);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [page, getArticles]);
  const breakpointColumnsObj = {
    default: 4,
    1480: 4,
    1300: 3,
    1000: 2,
    600: 1,
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'}>
      <Helmet>
        {page ? <title>{page}</title> : <title>مجلة براءات</title>}
      </Helmet>
      {isSmallScreen && (
        <Headroom
          className="book-filter"
          style={{
            top: '70px',
            left: '0px',
            right: '0px',
            zIndex: '1',
            transform: 'translate3D(0px, 0px, 0px)',
            transition: 'all .5s ease-in-out 0s',
          }}
        >
          <MagazineFilter />
        </Headroom>
      )}
      {!isSmallScreen && <MagazineFilter />}
      <Box m="7%" mt={{ base: '0', md: '1em' }} mb="160px">
        {!data && (
          <Box textAlign="center">
            <Spinner size="xl" />
          </Box>
        )}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data &&
            Array.isArray(data.articles) &&
            page &&
            data.articles.map(article => {
              const articleBody = article.body.split('\n');
              const body = articleBody[0] + '' + articleBody[1];

              return (
                <Link key={article.id} to={`/singlePost?id=${article.id}`}>
                  <Box
                    bg={bg[colorMode]}
                    w="100%"
                    shadow="lg"
                    cursor="pointer"
                    my="8"
                  >
                    <Box>
                      <Skeleton w="100%" isLoaded={loaded}>
                        <Image
                          w="100%"
                          onLoad={imageLoaded}
                          src={`${process.env.REACT_APP_STORAGE}/${article.image}`}
                        />
                      </Skeleton>
                      <Box p="4">
                        <Text
                          mt="4"
                          fontSize="lg"
                          fontFamily="diodrum-med !important"
                        >
                          {' '}
                          {article.author}{' '}
                        </Text>
                        <Heading
                          fontFamily="diodrum-med !important"
                          size="lg"
                          mb="4"
                        >
                          {article.title}
                        </Heading>
                        <Box
                          fontSize="lg"
                          className="event-body event-body__magazine"
                          dangerouslySetInnerHTML={{
                            __html: body,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Link>
              );
            })}
        </Masonry>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {Array.isArray(data) &&
            data &&
            !page &&
            data.map(article => (
              <Link key={article.id} to={`/singlePost?id=${article.id}`}>
                <Box
                  bg={bg[colorMode]}
                  w="100%"
                  shadow="lg"
                  cursor="pointer"
                  my="8"
                >
                  <Box>
                    <Skeleton w="100%" isLoaded={loaded}>
                      <Image
                        w="100%"
                        onLoad={imageLoaded}
                        src={`${process.env.REACT_APP_STORAGE}/${article.image}`}
                      />
                    </Skeleton>
                    <Box p="4">
                      <Text fontSize="lg" fontFamily="diodrum-med !important">
                        {' '}
                        {article.author}{' '}
                      </Text>
                      <Heading
                        fontFamily="diodrum-med !important"
                        size="lg"
                        mb="4"
                      >
                        {article.title}
                      </Heading>

                      <Box
                        fontSize="lg"
                        className="event-body event-body__magazine"
                        dangerouslySetInnerHTML={{
                          __html: article.body,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
        </Masonry>
        {data?.articles?.length === 0 && (
          <Text fontSize="1.5rem" mt={{ base: '2em', md: '3em' }}>
            لا يوجد كتاب حاليا
          </Text>
        )}
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getArticles: page => dispatch(getArticles(page)) };
};

export default connect(null, mapDispatchToProps)(Blog);
