import React from 'react';
import { Box, Text, Heading, Image, Divider } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import aboutpic from '../images/about-min.jpg';
export default function blog() {
  return (
    <Box mt={{ base: '1em', md: '8em' }} mb="100px">
      <Helmet>
        <title>عن براءات</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        عن براءات
      </Heading>

      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          مجلة "براءات" للشِّعر، مجلةٌ عربيَّةٌ فصليَّةٌ، تصدرُ عن منشورات
          المتوسّط، وتنشَغِل بكلِّ ما يتعلَّقُ بالشِّعر نصَّاً ومقالًا نقديَّاً
          ودراسةً نظريَّةً وتطبيقيَّةً وحواراً وتقريراً وقصَّاً ومقاربةً بين
          الشِّعر وفنون أخرى.
        </Text>
        <Text fontSize="2xl" m="4">
          تريدُ أنْ تكونَ سبَّابةً تدلُّ عارفي طريقهم إلى المتاهةِ، والراشدينَ
          إلى الغِوَاية، ومِنصَّة يُعوَّل عليها حينَ يُتحدَّث عن التجارب
          الشعريَّة العربيَّة المُعاصِرَة، والتي ستبحثُ "براءات" بشكلٍ خاصٍّ عن
          أصواتِهَا الجديدة والقديمة التي لم تُسمَع بعد.
        </Text>
        <Text fontSize="2xl" m="4">
          وتريدُ أيضاً التقريبَ بين تجارب الشُّعراء العَرَب وتكريس المُضيء منها.
          دافِعُنا إلى ذلك خلوّ الثقافة العربيَّة بشكلٍ يكادُ يكونُ تامَّاً من
          مَطبوعٍ شعريٍّ ذي هويَّة عربيَّة عابرة للبلدان.
        </Text>
        <Text fontSize="2xl" m="4">
          مجلَّة "براءات" تصدر ورقيَّةً على رأسِ كلِّ فصلٍ من فصول العام، عن دار
          المتوسِّط التي تُعبِّر عن نفسها بـأنَّها "دار نشر مُستقلَّة ذات موقفٍ
          جذريٍّ، تُؤسِّس ديناميكيَّة في اختياراتها وآلياتِ عملها انطلاقاً من
          موقفها". براءات تعمل تحت هذا التعريف ذاته.
        </Text>
      </Box>
    </Box>
  );
}
