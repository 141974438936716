import React from 'react';
import { Helmet } from 'react-helmet';

import {
  Heading,
  Box,
  Divider,
  useColorMode,
  useBreakpointValue,
} from '@chakra-ui/core';
import { BsArrowUpLeft } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
// import fx from 'money';
import { connect } from 'react-redux';
import { getBooks } from '../../redux/actions/booksActions';

import CatBooks from '../../components/books/catBooks';
import BooksFilter from '../../components/books/BooksFilter';
import Headroom from 'react-headroom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  let query = useQuery();
  let translate = query.get('translate');

  const color = { light: 'black', dark: 'white' };
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'} mb="100px">
      <Helmet>
        <title>كتب براءات</title>
      </Helmet>
      {isSmallScreen && (
        <Headroom
          className="book-filter"
          style={{
            top: '70px',
            left: '0px',
            right: '0px',
            zIndex: '1',
            transform: 'translate3D(0px, 0px, 0px)',
            transition: 'all .5s ease-in-out 0s',
          }}
        >
          <BooksFilter />
        </Headroom>
      )}
      {!isSmallScreen && <BooksFilter />}
      <Box px={['10%', '8%', '8%', '5%']} mt="1em" mb="100px">
        <CatBooks translate={translate} />
      </Box>
    </Box>
  );
}
const mapDispatchToProps = dispatch => {
  return { getBooks: () => dispatch(getBooks()) };
};

export default connect(null, mapDispatchToProps)(Home);
