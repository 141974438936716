import React from 'react';
import {
  SimpleGrid,
  Box,
  Image,
  Text,
  Heading,
  Divider,
  Skeleton,
  Flex,
  Spinner,
  useColorMode,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import Masonry from 'react-masonry-css';

import { getBooks } from '../../redux/actions/booksActions';

function CatBooks({ translate, getBooks, name }) {
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    async function getData() {
      const res = await getBooks(null, null, translate, null);
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [translate]);
  console.log(data && data.books);
  const breakpointColumns = {
    default: 4,
    1300: 4,
    1100: 3,
    1000: 2,
    700: 1,
  };

  return (
    <Box>
      {/* <Box d="flex" m="4">
        <Link to={`/books?translate=0`}>
          <Heading
            fontFamily="diodrum-med !important"
            fontWeight="normal"
            size="md"
            m="2"
          >
            عربي
          </Heading>
        </Link>
        <Link to={`/books?translate=1`}>
          <Heading
            fontFamily="diodrum-med !important"
            fontWeight="normal"
            size="md"
            m="2"
          >
            مترجم
          </Heading>
        </Link>
      </Box> */}
      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      <Masonry
        breakpointCols={breakpointColumns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data &&
          data.books &&
          data.books.length !== 0 &&
          data.books.map(book => (
            <Link key={book.id} to={`/book/${book.id}`}>
              <Box mt="8" shadow="lg" bg={bg[colorMode]}>
                <LazyLoad once height="350px">
                  <Skeleton w="100%" isLoaded={loaded}>
                    <Image
                      onLoad={imageLoaded}
                      w="100%"
                      m="0 auto"
                      shadow="lg"
                      src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                    ></Image>
                  </Skeleton>
                </LazyLoad>
                <Box p="1.125em" pt=".5em">
                  <Text
                    fontFamily="diodrum-med !important"
                    fontSize="2xl"
                    my="2"
                  >
                    {' '}
                    {book?.author[0]?.name}{' '}
                  </Text>
                  <Heading
                    fontFamily="diodrum-bold !important"
                    fontSize={{ base: '1.5rem', md: '1.8rem' }}
                    mb={4}
                  >
                    {' '}
                    {book.title}{' '}
                  </Heading>

                  <Box
                    fontSize="xl"
                    className="content books__content"
                    dangerouslySetInnerHTML={{ __html: book.overview }}
                  />
                </Box>
              </Box>
            </Link>
          ))}
      </Masonry>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getBooks: (category, featured, furthercoming, translate) =>
      dispatch(getBooks(category, featured, furthercoming, translate)),
  };
};

export default connect(null, mapDispatchToProps)(CatBooks);
