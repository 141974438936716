import React from 'react';

import {
  Flex,
  Text,
  useColorMode,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/core';
// import { ChevronDownIcon } from '@chakra-ui/icons';
import { NavLink, Link, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FaTimes } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

import { connect } from 'react-redux';
import { getSeries } from '../../redux/actions/seriesActions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Navbar({ getSeries }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isLargerThan1110] = useMediaQuery('(min-width: 1110px)');

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const hoverBg = { light: '#000', dark: 'white' };
  const hoverClr = { light: 'white', dark: '#000' };
  const page = useQuery().get('page');

  const isActiveLink = link => {
    return page === link;
  };

  return (
    <Flex
      borderBottom="1px solid #ddd"
      as="nav"
      align="center"
      bg={bg[colorMode]}
      overflowX={{ base: 'auto', sm: 'auto' }}
      wrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
      direction={['row', 'row', 'row', 'row']}
    >
      {!isLargerThan1110 && (
        <IconButton
          mr="1em"
          onClick={onOpen}
          icon={<GiHamburgerMenu size="25px" />}
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        />
      )}
      {isLargerThan1110 && (
        <Flex>
          <NavLink
            activeStyle={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
              height: '100%',
              marginLeft: 5,
            }}
            to="/versions"
          >
            <Flex
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                الاعداد
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('طسم') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=طسم"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                طسم
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('يتبعهم الغاوون') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=يتبعهم الغاوون"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                يتبعهم الغاوون
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('يأفكون') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=يأفكون"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                يأفكون
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('يهيمون') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=يهيمون"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                يهيمون
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('من كل واد') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=من كل واد"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                من كل واد
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('لغوّ') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=لغوّ"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                لغوّ
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('كل يوم في شأن') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=كل يوم في شأن"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                كل يوم في شأن
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={
              isActiveLink('ختم') && {
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'black',
                height: '100%',
                marginLeft: 5,
              }
            }
            to="/magazine?page=ختم"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                ختم
              </Text>
            </Flex>
          </NavLink>
        </Flex>
      )}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay />
        <DrawerContent
          bg={colorMode === 'dark' ? bg[colorMode] : '#000'}
          color="white"
        >
          <DrawerHeader p="0">
            <IconButton
              p={8}
              onClick={onClose}
              icon={<FaTimes size="25px" />}
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            />
          </DrawerHeader>
          <DrawerBody p="0">
            <Flex direction="column">
              <NavLink
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black',
                  height: '100%',
                  marginLeft: 5,
                }}
                to="/versions"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    الاعداد
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('طسم') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=طسم"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    طسم
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('يتبعهم الغاوون') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=يتبعهم الغاوون"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    يتبعهم الغاوون
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('يأفكون') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=يأفكون"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    يأفكون
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('يهيمون') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=يهيمون"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    يهيمون
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('من كل واد') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=من كل واد"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    من كل واد
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('لغوّ') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=لغوّ"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    لغوّ
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('كل يوم في شأن') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=كل يوم في شأن"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    كل يوم في شأن
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={
                  isActiveLink('ختم') && {
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: 'black',
                    height: '100%',
                    marginLeft: 5,
                  }
                }
                to="/magazine?page=ختم"
              >
                <Flex
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    ختم
                  </Text>
                </Flex>
              </NavLink>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

const mapDispatchToProps = dispatch => {
  return { getSeries: () => dispatch(getSeries()) };
};

export default connect(null, mapDispatchToProps)(Navbar);
