import React from 'react';
import {
  Box,
  Alert,
  Heading,
  Stack,
  Flex,
  useColorMode,
} from '@chakra-ui/core';
import { Helmet } from 'react-helmet';
import { GoVerified } from 'react-icons/go';

import Resend from './Resend';

export default function NotVerified({ message }) {
  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: '#151a23' };
  return (
    // <Box
    //   fontFamily="diodrum-med !important"
    //   pr="10%"
    //   pl="10%"
    //   textAlign="center"
    //   mb="500px"
    // >
    //   <Box p="4" bg="gray.500">
    //     {message}
    //   </Box>
    //   <Resend></Resend>
    // </Box>
    <Box
      fontFamily="diodrum-med !important"
      //   mt="130px"
      mb="160px"
      textAlign="center"
    >
      <Helmet>
        <title>تأكيد الايميل</title>
      </Helmet>
      <Box>
        <Flex justifyContent="center">
          <Stack
            shadow="lg"
            p={{ base: '4', md: '8' }}
            bg={bg[colorMode]}
            w="500px"
          >
            <Flex justifyContent="center">
              <GoVerified style={{ color: 'green', fontSize: '60px' }} />
            </Flex>
            <Heading fontFamily="diodrum-bold !important" mb="4">
              تأكيد الايميل
            </Heading>
            <Box px={{ base: '0', md: '4' }} py="4" color="white" bg="gray.400">
              لقد أرسلنا ايميل الى حسابك المسجل لاجراء عملية التأكيد
            </Box>
            <Resend></Resend>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}
