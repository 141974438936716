import React from 'react';
import {
  Box,
  Heading,
  Input,
  Flex,
  Stack,
  Button,
  InputGroup,
  Text,
  Alert,
  useToast,
  useColorMode,
} from '@chakra-ui/core';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckoutForm = ({ plan_id, plan_name, plan_amount, auth }) => {
  const toast = useToast();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };

  //headers
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  React.useEffect(() => {
    async function getData() {
      const getIntent = await axios.get(
        `${process.env.REACT_APP_API}/subscribe`,
        { headers }
      );
      setData(getIntent);
    }
    getData();
  }, []);

  //subscribe
  const handleSubmitSub = async event => {
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: data.data.user.email,
      },
    });

    console.log(result);

    if (result.error) {
      console.log(result.error.message);
    } else {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/subscribe`,
        {
          payment_method: result.paymentMethod.id,
          email: data.data.user.email,
          plan: plan_id,
        },
        { headers }
      );
      console.log(res);
      setLoading(false);
      if (!res.status === 200) {
        toast({
          //  title: 'Account created.',
          description: 'حدث خطا ما اعد المحاولة ',
          position: 'top-right',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          //  title: 'Account created.',
          description: 'تم التسجيل بنجاح',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.replace('/message');
        }, 1000);
      }
    }
  };

  return (
    <Box
      fontFamily="diodrum-med !important"
      bg={bg[colorMode]}
      shadow="lg"
      p="8"
    >
      <Helmet>
        <title>اشتراك في براءت</title>
      </Helmet>

      <Text mb="2" fontSize="lg">
        رقم البطاقة
      </Text>
      <Box mt="8" mb="4" dir="ltr" textAlign="left">
        <CardElement />
      </Box>
      <Button
        isDisabled={loading}
        isLoading={loading}
        colorScheme="blue"
        onClick={handleSubmitSub}
      >
        سجل
      </Button>
    </Box>
  );
};

function Subscribe({ auth }) {
  let query = useQuery();
  let plan_id = query.get('plan_id');
  let plan_name = query.get('plan_name');
  let plan_amount = query.get('plan_amount');

  return (
    <Box maxW="450px" my={{ base: '3em', md: '10em' }} mx="auto" p="1em">
      <CheckoutForm
        auth={auth}
        plan_id={plan_id}
        plan_name={plan_name}
        plan_amount={plan_amount}
      />
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Subscribe);
