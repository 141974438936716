import React, { useState, useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Box,
  Heading,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Image,
  useColorMode,
  useMediaQuery,
  useBreakpointValue,
} from '@chakra-ui/core';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/authAction';
import { Link, useRouteMatch } from 'react-router-dom';
import { FaMoon, FaSun, FaChevronUp } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

import logo from '../../images/logo.png';

function Navbar({ auth, logout }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSmallerThan1065] = useMediaQuery('(max-width: 1065px)');
  let { path, url } = useRouteMatch();

  const [show, setShow] = useState(false);
  const btnRef = useRef();

  const bg = { light: '#000000', dark: '#1a202c' };
  const color = { light: 'white', dark: 'white' };
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const handleToggle = () => setShow(!show);

  return (
    <>
      <Flex
        style={
          !isSmallScreen
            ? {
                position: 'fixed',
                width: '100%',
                zIndex: '99',
                bottom: 0,
                right: 0,
              }
            : {}
        }
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="0.5rem"
        borderTop="1px solid white"
        //   shadow="lg"
        color={color[colorMode]}
        bg={bg[colorMode]}
      >
        <Flex align="center" mr={5}>
          <Link to="/">
            <Image
              mr="20%"
              w="140px"
              // h="40px"
              className="main-logo"
              src={logo}
            ></Image>
          </Link>
        </Flex>

        <Box display={isSmallerThan1065 ? 'block' : 'none'} onClick={onOpen}>
          <GiHamburgerMenu
            style={{ marginLeft: 10, fontSize: 28 }}
          ></GiHamburgerMenu>
        </Box>

        <Box
          display={isSmallerThan1065 ? 'none' : 'flex'}
          width={{ base: 'full', md: 'auto' }}
          alignItems="center"
          textAlign="center"
          justifyContent="flex-end"
          flexGrow={1}
        >
          {/* <MenuItems>JE SUIS COMMERÇANT</MenuItems> */}
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to="/about">
              عن براءات
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to="/magazine">
              المجلة
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to="/books">
              الكتب
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to="/writers">
              الكتّاب
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to="/podcast">
              بودكاست
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to="/videos">
              فيديو
            </Link>
          </Text>

          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to={'/support'}>
              ادعم براءات
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            fontWeight="bold"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontSize="18px"
          >
            <Link onClick={handleToggle} to={'/message'}>
              اشترك في براءات
            </Link>
          </Text>
          {/* <Text
          fontFamily="diodrum-bold !important"
          fontWeight="bold"
          mt={{ base: 4, md: 0 }}
          ml={8}
          display="block"
          fontSize="18px"
        >
          <Link onClick={handleToggle} to="/shop">
            متجر براءات
          </Link>
        </Text> */}
          <Menu>
            <MenuButton
              // px={4}
              // py={2}
              mt={{ base: 4, md: 0 }}
              fontSize="lg"
              fontWeight="bold"
              transition="all 0.2s"
              fontFamily="diodrum-bold !important"

              // _hover={{ bg: 'gray.100' }}
              // _focus={{ outline: 0, boxShadow: 'outline' }}
            >
              الحساب <FaChevronUp style={{ display: 'inline' }}></FaChevronUp>
            </MenuButton>
            <MenuList bg={bg[colorMode]} shadow="xl">
              {auth && !auth.data && (
                <>
                  <Link to="/login">
                    <MenuItem
                      onClick={handleToggle}
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      تسجيل الدخول
                    </MenuItem>
                  </Link>
                  <Link to="/register">
                    <MenuItem
                      onClick={handleToggle}
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      تسجيل جديد
                    </MenuItem>
                  </Link>
                </>
              )}

              {auth.data && (
                <>
                  <Link to="/profile">
                    <MenuItem
                      onClick={handleToggle}
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      حسابي
                    </MenuItem>
                  </Link>
                  {/* <Link to="/login">
                  <MenuItem
                    onClick={handleToggle}
                    _focus={{ bg: 'white', color: 'black' }}
                    _hover={{ bg: 'white', color: 'black' }}
                    fontSize="xl"
                  >
                    مفضلتي
                  </MenuItem>
                </Link>
                <Link to="/login">
                  <MenuItem
                    onClick={handleToggle}
                    _focus={{ bg: 'white', color: 'black' }}
                    _hover={{ bg: 'white', color: 'black' }}
                    fontSize="xl"
                  >
                    مشترياتي
                  </MenuItem>
                </Link> */}

                  <MenuItem
                    onClick={() => {
                      logout();
                      handleToggle();
                      // window.location.replace('/');
                    }}
                    _focus={{ bg: 'white', color: 'black' }}
                    _hover={{ bg: 'white', color: 'black' }}
                    fontSize="xl"
                  >
                    خروج
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
          <Box
            cursor="pointer"
            mx="1.5vw"
            mt={['6', '6', '0', '0']}
            fontSize="24px"
            onClick={toggleColorMode}
          >
            {' '}
            {colorMode === 'light' ? <FaMoon></FaMoon> : <FaSun></FaSun>}
          </Box>
          {/* <Up></Up> */}
        </Box>
      </Flex>

      {/*Moblile nav*/}

      <Drawer
        // style={{dir:"rtl"}}

        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent color={color[colorMode]} bg={bg[colorMode]}>
          {/*<DrawerCloseButton/>*/}
          <DrawerHeader>
            <Flex wrap={'nowrap'} style={{ justifyContent: 'space-between' }}>
              <Link onClick={onClose} to="/">
                <Heading
                  cursor="pointer"
                  fontWeight="bold"
                  as="h1"
                  fontSize="36px"
                  letterSpacing={'-.1rem'}
                >
                  <Image
                    loading="lazy"
                    w="200px"
                    className="main-logo"
                    src={logo}
                  ></Image>
                </Heading>
              </Link>
              <Box cursor="pointer" fontSize="24px" onClick={toggleColorMode}>
                {' '}
                {colorMode === 'light' ? <FaMoon></FaMoon> : <FaSun></FaSun>}
              </Box>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to="/about">
                عن براءات
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to="/magazine">
                المجلة
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to="/books">
                الكتب
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to="/writers">
                الكتّاب
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to="/podcast">
                بودكاست
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to="/videos">
                فيديو
              </Link>
            </Text>

            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to={'/support'}>
                ادعم براءات
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              fontWeight="bold"
              mt={4}
              ml={8}
              display="block"
              fontSize="18px"
            >
              <Link onClick={onClose} to={'/message'}>
                اشترك في براءات
              </Link>
            </Text>
          </DrawerBody>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return { logout: () => dispatch(logout()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
