import React from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  Divider,
  Tag,
  TagLabel,
  Select,
  Button,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  useColorMode,
} from '@chakra-ui/core';
import {
  cancelSub,
  resumeSub,
  changeSub,
  getPlans,
} from '../../redux/actions/stripeActions';
import { connect } from 'react-redux';

function Stripe({ stripe, cancelSub, resumeSub, changeSub, getPlans }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const [resume, setResume] = React.useState(false);
  const [changeLoading, setChangeLoading] = React.useState(false);
  const [plans, setPlans] = React.useState(null);
  const [change, setChange] = React.useState(null);
  const [permanentCancel, setPermanentCancel] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const toast = useToast();
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };

  const handleCancel = async anf => {
    setCancel(true);
    const res = await cancelSub({ subid: stripe.data.subscription.id, now: 0 });
    if (!res.status === 200) {
      toast({
        //  title: 'Account created.',
        description: 'حدث خطا ما اعد المحاولة ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setCancel(false);
    } else {
      toast({
        //  title: 'Account created.',
        description: 'تم الغاء الاشتراك بنجاح',
        position: 'top-right',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.replace('/message');
      }, 1000);
    }
  };
  const handlePermanentCancel = async anf => {
    setPermanentCancel(true);
    const res = await cancelSub({ subid: stripe.data.subscription.id, now: 1 });
    if (!res.status === 200) {
      toast({
        //  title: 'Account created.',
        description: 'حدث خطا ما اعد المحاولة ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setPermanentCancel(false);
    } else {
      toast({
        //  title: 'Account created.',
        description: 'تم الغاء الاشتراك بنجاح',
        position: 'top-right',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.replace('/message');
      }, 1000);
    }
  };
  const handleResume = async anf => {
    setResume(true);
    const res = await resumeSub(stripe.data.subscription.id);
    if (!res.status === 200) {
      toast({
        //  title: 'Account created.',
        description: 'حدث خطا ما اعد المحاولة ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setResume(false);
    } else {
      toast({
        //  title: 'Account created.',
        description: 'تم استئناف الاشتراك بنجاح',
        position: 'top-right',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.replace('/message');
      }, 1000);
    }
  };
  const handleSelectChange = e => {
    setChange(e.target.value);
  };
  const handleChangeSubmit = async e => {
    setChangeLoading(true);
    const res = await changeSub({
      subid: stripe.data.subscription.id,
      plan: change,
    });
    if (!res.status === 200) {
      toast({
        //  title: 'Account created.',
        description: 'حدث خطا ما اعد المحاولة ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setChangeLoading(false);
    } else {
      toast({
        //  title: 'Account created.',
        description: 'تم تغيير الاشتراك بنجاح',
        position: 'top-right',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.replace('/message');
      }, 1000);
    }
  };

  React.useEffect(() => {
    async function getData() {
      const res = await getPlans();
      console.log('plans', res);
      const filterdPlans = res?.data?.plans?.filter(plan => {
        return plan.id !== stripe.data.subscription.stripe_plan;
      });
      console.log(filterdPlans);
      setPlans(filterdPlans);
    }
    getData();
  }, []);
  return (
    <Box
      fontFamily="diodrum-med !important"
      className="padding"
      mt="160px"
      mb="160px"
    >
      <Box p="8" borderRadius="10px" bg={bg[colorMode]} shadow="lg">
        <Heading size="lg" mt="4" mb="4">
          الاشتراك
        </Heading>
        <Flex wrap="wrap" justifyContent="space-between">
          <Text fontSize="lg" fontWeight="bold">
            الخطة
          </Text>
          <Text fontSize="lg">{stripe.data.subscription.stripe_plan}</Text>
        </Flex>
        <Divider mt="4" mb="4"></Divider>
        <Flex wrap="wrap" justifyContent="space-between">
          <Text fontSize="lg" fontWeight="bold">
            تاريخ الاشتراك
          </Text>
          <Text fontSize="lg">{stripe.data.subscription.created_at}</Text>
        </Flex>
        <Divider mt="4" mb="4"></Divider>
        <Flex wrap="wrap" justifyContent="space-between">
          <Text fontSize="lg" fontWeight="bold">
            الحالة
          </Text>
          <Tag
            size="lg"
            borderRadius="full"
            variant="solid"
            colorScheme="green"
          >
            <TagLabel>{stripe.data.subscription.stripe_status}</TagLabel>
          </Tag>
        </Flex>
        <Divider mt="4" mb="4"></Divider>
        <Heading size="lg" mt="4" mb="4">
          ادارة الاشتراك
        </Heading>
        {plans && (
          <>
            <Flex wrap="wrap" justifyContent="space-between">
              <Text fontSize="lg" fontWeight="bold">
                تغيير الخطة
              </Text>
              <Box d="flex" w="80%" justifyContent="space-between">
                <Select
                  onChange={handleSelectChange}
                  w="80%"
                  placeholder="حدد نوع الاشتراك"
                >
                  {plans.map(plan => (
                    <option value={plan.id}>{plan.product.name}</option>
                  ))}
                </Select>
                <Button
                  onClick={handleChangeSubmit}
                  isLoading={changeLoading}
                  isDisabled={!change}
                  colorScheme="blue"
                >
                  تغيير
                </Button>
              </Box>
            </Flex>
            <Divider mt="4" mb="4"></Divider>
          </>
        )}
        {!stripe.data.subscription.cancelled && (
          <>
            <Flex wrap="wrap" justifyContent="space-between">
              <Text fontSize="lg" fontWeight="bold">
                الغاء الاشتراك
              </Text>
              <>
                <Button colorScheme="red" onClick={() => setIsOpen(true)}>
                  الغاء
                </Button>

                <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                  fontFamily="diodrum-med !important"
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader
                        fontFamily="diodrum-med !important"
                        fontSize="lg"
                        fontWeight="bold"
                      >
                        الغاء الاشتراك
                      </AlertDialogHeader>

                      <AlertDialogFooter fontFamily="diodrum-med !important">
                        <Button ml={3} ref={cancelRef} onClick={onClose}>
                          رجوع
                        </Button>
                        <Button
                          isLoading={cancel}
                          isDisabled={cancel || permanentCancel}
                          colorScheme="red"
                          onClick={handleCancel}
                          ml={3}
                        >
                          الغاء مؤقت{' '}
                        </Button>
                        <Button
                          isLoading={permanentCancel}
                          isDisabled={permanentCancel || cancel}
                          colorScheme="red"
                          onClick={handlePermanentCancel}
                          ml={3}
                        >
                          الغاء نهائي
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            </Flex>
            <Divider mt="4" mb="4"></Divider>
          </>
        )}

        {stripe.data.subscription.cancelled &&
          stripe.data.subscription.on_grace_period && (
            <>
              <Flex wrap="wrap" justifyContent="space-between">
                <Text fontSize="lg" fontWeight="bold">
                  استئناف الاشتراك
                </Text>
                <Button
                  colorScheme="green"
                  isLoading={resume}
                  isDisabled={resume}
                  onClick={handleResume}
                >
                  استئناف
                </Button>
              </Flex>
              <Divider mt="4" mb="4"></Divider>
            </>
          )}
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getPlans: payload => dispatch(getPlans(payload)),
    cancelSub: payload => dispatch(cancelSub(payload)),
    resumeSub: payload => dispatch(resumeSub(payload)),
    changeSub: payload => dispatch(changeSub(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Stripe);
