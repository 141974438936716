import React from 'react';
import { Box, Heading } from '@chakra-ui/core';

export default function videos() {
  return (
    <Box textAlign="center" mt="100px" mb="100px">
      <Heading>متجر براءات</Heading>
    </Box>
  );
}
