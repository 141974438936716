import React, { useState, useEffect } from 'react';
import { Alert, Spinner, Box, AlertIcon, AlertTitle } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { verifyEmail } from '../redux/actions/verifyActions';
import Resend from '../components/Resend';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Verified({ verifyEmail }) {
  let query = useQuery();
  let token = query.get('token');
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      const res = await verifyEmail(token);
      setData(res);
      console.log(res);
    }
    getData();
  }, [verifyEmail, token]);

  return (
    <Box
      fontFamily="diodrum-med !important"
      textAlign="center"
      px={{ base: '1em', md: '10%' }}
      my="200px"
    >
      {!data ? (
        <Spinner />
      ) : (
        <>
          {data?.status === 200 ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="150px"
              mb="8"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {data?.data?.message}
              </AlertTitle>
            </Alert>
          ) : (
            <>
              <Alert
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="150px"
                mb="8"
                mx="auto"
                maxW="1000px"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  {data?.data?.message}
                </AlertTitle>
              </Alert>
              <Resend />
            </>
          )}
        </>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { verifyEmail: token => dispatch(verifyEmail(token)) };
};

export default connect(null, mapDispatchToProps)(Verified);
