import React from 'react';
import {
  SimpleGrid,
  Box,
  Image,
  Text,
  Heading,
  Divider,
  Skeleton,
  Spinner,
  useColorMode,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import Masonry from 'react-masonry-css';

import { getBooks } from '../../redux/actions/booksActions';

function CatBooks({ translate, furthercoming, getBooks }) {
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  React.useEffect(() => {
    let response;
    async function getData() {
      const res = await getBooks(null, null, translate, furthercoming);
      if (res) {
        console.log(response);
        setData(Object.values(res.data));
      }
    }
    getData();
  }, [translate]);
  const breakpointColumns = {
    default: 4,
    1300: 4,
    1100: 3,
    1000: 2,
    700: 1,
  };

  return (
    <Box mt={{ base: '0em', md: '8em' }} mb="100px">
      <Box>
        {/* <Box d="flex" m="4">
          <Link to={`/furthercoming?furthercoming=1&translate=0`}>
            <Heading
              fontFamily="diodrum-med !important"
              fontWeight="normal"
              size="md"
              m="2"
            >
              عربي
            </Heading>
          </Link>
          <Link to={`/furthercoming?furthercoming=1&translate=1`}>
            <Heading
              fontFamily="diodrum-med !important"
              fontWeight="normal"
              size="md"
              m="2"
            >
              مترجم
            </Heading>
          </Link>
        </Box> */}
        {!data && (
          <Box textAlign="center">
            <Spinner size="xl" />
          </Box>
        )}
        {/* <SimpleGrid columns={[1, 2, 3, 5]}> */}
        <Masonry
          breakpointCols={breakpointColumns}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data &&
            data.length !== 0 &&
            data.map(book => (
              <Link key={book.id} to={`/book/${book.id}`}>
                <Box mt="8" pb="4" shadow="lg" bg={bg[colorMode]}>
                  <LazyLoad once height="350px">
                    <Skeleton w="100%" isLoaded={loaded}>
                      <Image
                        onLoad={imageLoaded}
                        w="100%"
                        m="0 auto"
                        shadow="lg"
                        src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                      ></Image>
                    </Skeleton>
                  </LazyLoad>
                  <Text
                    fontFamily="diodrum-med !important"
                    fontSize="2xl"
                    m="2"
                  >
                    {' '}
                    {book?.author[0]?.name}{' '}
                  </Text>
                  <Heading fontFamily="diodrum-bold !important" m="4">
                    {' '}
                    {book.title}{' '}
                  </Heading>

                  <Box
                    m="4"
                    fontSize="xl"
                    className="content"
                    dangerouslySetInnerHTML={{ __html: book.overview }}
                  ></Box>
                </Box>
              </Link>
            ))}
        </Masonry>
        {data?.length === 0 && (
          <Text textAlign="center" fontSize="1.5rem">
            لا يوجد كتاب حاليا
          </Text>
        )}
        {/* </SimpleGrid> */}
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getBooks: (category, featured, translate, furthercoming) =>
      dispatch(getBooks(category, featured, translate, furthercoming)),
  };
};

export default connect(null, mapDispatchToProps)(CatBooks);
